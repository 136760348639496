// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/button.tsx");
  import.meta.hot.lastModified = "1731760300000";
}
// REMIX HMR END

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { Loader2 } from 'lucide-react';
import { cn } from '~/lib/utils';
const buttonVariants = cva('inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-stone-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-stone-950 dark:focus-visible:ring-stone-300', {
  variants: {
    variant: {
      default: 'bg-primary-500 text-stone-50 hover:bg-primary-500/90 dark:bg-primary-900 dark:text-stone-50 dark:hover:bg-primary-900/90',
      destructive: 'bg-red-500 text-stone-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-stone-50 dark:hover:bg-red-900/90',
      outline: 'border border-stone-200 bg-white hover:bg-stone-100 hover:text-stone-900 dark:border-stone-800 dark:bg-stone-950 dark:hover:bg-stone-800 dark:hover:text-stone-50',
      ghost: 'hover:bg-stone-100 hover:text-stone-900 dark:hover:bg-stone-800 dark:hover:text-stone-50',
      link: 'text-stone-900 underline-offset-4 hover:underline dark:text-stone-50',
      secondary: 'bg-secondary-500 text-stone-50 hover:bg-secondary-500/90 dark:bg-secondary-900 dark:text-stone-50 dark:hover:bg-secondary-900/90',
      ternary: 'bg-ternary-500 text-stone-50 hover:bg-ternary-500/90 dark:bg-ternary-900 dark:text-stone-50 dark:hover:bg-ternary-900/90',
      outlinePrimary: 'border border-primary-200 bg-white hover:bg-primary-100 hover:text-primary-900 text-primary-800 dark:border-primary-800 dark:bg-primary-950 dark:hover:bg-primary-800 dark:hover:text-primary-50',
      outlineSecondary: 'border border-secondary-200 bg-white hover:bg-secondary-100 hover:text-secondary-900 text-secondary-800 dark:border-secondary-800 dark:bg-secondary-950 dark:hover:bg-secondary-800 dark:hover:text-secondary-50',
      outlineTernary: 'border border-ternary-200 bg-white hover:bg-ternary-100 hover:text-ternary-900 text-ternary-800 dark:border-ternary-800 dark:bg-ternary-950 dark:hover:bg-ternary-800 dark:hover:text-ternary-50'
    },
    size: {
      default: 'h-10 px-4 py-2',
      sm: 'h-9 rounded-md px-3',
      lg: 'h-11 rounded-md px-8',
      icon: 'h-10 w-10',
      xs: 'h-auto rounded-md px-2 py-1 text-xs'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
const Button = React.forwardRef(_c = ({
  className,
  variant,
  size,
  asChild = false,
  children,
  isProcessing,
  type = 'button',
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }), isProcessing ? 'pointer-events-none' : '')} type={type} ref={ref} {...props}>
        {isProcessing ? <>
            <Loader2 className='mr-2 h-4 w-4 animate-spin' />
            Please wait...
          </> : children}
      </Comp>;
});
_c2 = Button;
Button.displayName = "Button";
export { Button, buttonVariants };
var _c, _c2;
$RefreshReg$(_c, "Button$React.forwardRef");
$RefreshReg$(_c2, "Button");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;